<template>
  <div
    class="p-[10px] flex flex-col justify-between transition rounded-md last:mb-0"
    :class="{
      'hover:bg-slate-500 cursor-pointer': canHover || hasBreakdown,
      'bg-slate-500': active,
      'opacity-40 cursor-default': disabled
    }"
    @click.prevent.stop="toggleBreakdown()"
  >
  <div class="flex items-center justify-between text-xs font-bold">
    <div class="flex items-center">
      <SvgIcon
        :icon="balance.name"
        :width="iconSize"
        :height="iconSize"
        class="my-auto"
      />
      <span
        class="mx-2"
        :class="{'hidden mx-2 xs:inline': showButtons}"
      >
        {{ balance.label }}
      </span>
      <CurrencyBadge :currency="balance.name"/>
      <span
        v-if="showButtons && balance.factor !== 1"
        v-tippy="{ content: `1 ${balance.name} = ${balance.factor} ${balance.baseCode}` }"
        class="icon-ico-info text-base text-yellow-500 font-bold ml-1"
      />
      <div v-if="hasBreakdown" class="ml-2 text-lg text-slate-200 flex items-center">
        <span
          class="icon-arrow-down-fill transition-transform"
          :class="{'rotate-180': showBreakdown}"
        />
      </div>
    </div>
    <div class="flex items-center">
      <BalanceValue
        v-if="balance"
        :balance="balance"
        :max-decimals="maxDecimals"
        class="text-xs truncate transition-all"
      />
      <div v-if="showButtons">
        <div class="actions ml-[10px]">
          <button
            type="submit"
            class="p-[10px] text-blue cursor-pointer rounded-md group"
            :class="{ 'opacity-40': disableDeposit, ' hover:text-white': !disableDeposit }"
            :disabled="disableDeposit"
            @click.prevent.stop="handleOpenTxModal('Deposit')"
          >
            <span
              class="icon-ico-arrow-bold-down border-2 border-transparent border-b-blue-500 rounded-full p-1"
              :class="{ 'group-hover:border-b-white': !disableDeposit }"
            />
            <span class="hidden md:inline-block ml-2">
              Deposit
            </span>
          </button>
          <button
            type="submit"
            class="p-[10px] text-blue cursor-pointer rounded-md group"
            :class="{ 'opacity-40': disableWithdraw, ' hover:text-white': !disableWithdraw }"
            :disabled="disableWithdraw"
            @click.prevent.stop="handleOpenTxModal('Withdraw')"
          >
            <span
              class="icon-ico-arrow-bold-up border-2 border-transparent border-b-blue-500 rounded-full p-1"
              :class="{ 'group-hover:border-b-white': !disableWithdraw }"
            />
            <span class="hidden md:inline-block ml-2">
              Withdraw
            </span>
          </button>
        </div>
      </div>
      <div
        v-if="showToggle"
        class="pt-1 ml-1 md:ml-2"
      >
        <tippy placement="bottom">
          <CheckboxSwitch
            v-model="selectorActive"
            small
            @click.stop=""
            @update:model-value="hideFlagUpdated"
          />
          <template #content>
            {{ selectorActive ? 'Showing' : 'Hidden' }}
            on balance selector
          </template>
        </tippy>
      </div>
    </div>
    </div>
    <Transition name="fade">
      <WalletBalanceBreakdown v-if="showBreakdown" :balance="balance"/>
    </Transition>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useBankingStore } from '@/store/banking';
import { useUiStore } from '@/store/ui';
import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';

const { canHover = false, ...props } = defineProps({
  balance: {
    type: Object,
    default: null,
  },
  size: {
    validator(value) {
      return ['sm', 'md', 'lg',].includes(value);
    },
    default: 'md',
  },
  canHover: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
  showButtons: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  maxDecimals: {
    type: Number,
    default: 6,
  },
  enableBreakdown: {
    type: Boolean,
    default: false,
  },
  showToggle: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const { openTxModal, } = uiStore;
const bankingStore = useBankingStore();
const { allBalances, } = storeToRefs(bankingStore);
const { hiddenBalances, } = storeToRefs(uiStore);
const showBreakdown = ref(false);
const selectorActive = ref(!hiddenBalances.value[props.balance.context]?.[props.balance.name]);

const iconSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return '18';
    case 'md':
      return '24';
    case 'lg':
      return '36';
    default:
      return '24';
  }
});

const disableDeposit = computed(() => {
  return allBalances.value?.[props.balance.name]?.virtual;
});

const disableWithdraw = computed(() => {
  return allBalances.value?.[props.balance.name]?.virtual || props.balance.value === 0;
});

const hasBreakdown = computed(() => {
  return props.enableBreakdown && props.balance?.breakdown?.length > 1;
});

function handleOpenTxModal(type) {
  if (props.balance.context === 'Default') {
    openTxModal(type, walletModalSubModeTypes[0], props.balance.name);
  } else {
    openTxModal(type, walletModalSubModeTypes[1], props.balance.name);
  }
}

function toggleBreakdown() {
  if (!hasBreakdown.value) { return; }
  showBreakdown.value = !showBreakdown.value;
}

function hideFlagUpdated(value) {
  if (!hiddenBalances.value[props.balance.context]) {
    hiddenBalances.value[props.balance.context] = {};
  }

  if (!value) {
    hiddenBalances.value[props.balance.context][props.balance.name] = true;
    return;
  }

  delete hiddenBalances.value[props.balance.context]?.[props.balance.name];
}
</script>
